.card-header {
  text-align: center;
  position: relative;
  background-color: #ffffff;
  padding-top: 100px; /* Adjust padding as necessary to account for the logo banner */
  max-width: 500px;
}

.logo-banner {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: #f7f7f7;
  padding: 0 0 20px 0; */
}

.logo {
  width: 100%; /* Adjust size as needed */
  height: auto;
  display: block;
  margin: 0 auto;
}

.profile-image {
  width: 240px; /* Adjust the size as needed */
  height: 240px; /* Ensure the height matches the width */
  border-radius: 50%; /* Makes the image round */
  object-fit: cover; /* Ensures the image covers the entire circle without distortion */
  margin-top: -30px; /* Adjusts the image position to overlap with the banner */
  position: relative;
  z-index: 1;
  display: inline;
}

.card-header .name {
  font-size: 34px;
  color: #333333;
  margin: 20px 0 10px 0;
  padding: 0 25px;
  font-weight: bold;
  text-transform: uppercase;
}

.card-header .job-position {
  font-size: 20px;
  color: #4e21a0;
  margin: 5px 0;
  padding: 0 30px;
  font-weight: bold;
  text-transform: uppercase;
}

@media (max-width: 480px) {
  /* Your styles for mobile devices */
  .profile-image {
    width: 215px; /* Adjust the size as needed */
    height: 215px; /* Ensure the height matches the width */
    margin-top: -40px; /* Adjusts the image position to overlap with the banner */
  }

  .card-header .name {
    font-size: 35px;
    margin: 10px 0 10px 0;
  }

  .card-header .job-position {
    font-size: 27px;
  }
}

@media (max-width: 768px) {
  /* Your styles for small tablets and large smartphones */
}

@media (max-width: 992px) {
  /* Your styles for tablets */
}

@media (max-width: 1200px) {
  /* Your styles for landscape tablets and small laptops */
}

@media (min-width: 1201px) {
  /* Your styles for large devices */
}
