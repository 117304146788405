/* Modal Styles */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0; /* Start hidden */
  transition: opacity 0.5s ease; /* Fade effect */
}

/* Entrance Animation */
.modal.show {
  opacity: 1; /* Fully visible */
  animation: fadeIn 0.5s forwards; /* Apply fadeIn animation */
}

/* Exit Animation */
.modal.hide {
  animation: fadeOut 0.5s forwards; /* Apply fadeOut animation */
}

/* Keyframes for Fade In */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.5); /* Slightly scale up */
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Keyframes for Fade Out */
@keyframes fadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.5); /* Slightly scale down */
  }
}

/* Modal Content Styles */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: auto;
  max-width: 80%;
  position: relative;
}

.modal-content .modal-title {
  display: flex;
  justify-content: center;
  margin: 30px 0 10px;
  color: #000;
}

.modal-content p {
  color: #000;
}

.close-button {
  color: #aaa;
  float: right;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
  margin: 10px;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-image {
  max-width: 400px;
  height: auto; /* Maintain aspect ratio */
}

.modal-button {
  color: white; /* White text */
  border: none; /* No border */
  padding: 10px 20px; /* Some padding */
  text-align: center; /* Centered text */
  text-decoration: none; /* No underline */
  display: block; /* Inline-block */
  font-size: 16px; /* Font size */
  margin: 10px auto; /* Margin */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 50px; /* Rounded corners */
}

.copy-link {
  background-color: #3218a5; /* Green background */
}

.copy-link:hover {
  background-color: #422da0; /* Darker green on hover */
}

.share-to-whatsapp {
  background-color: #23ad57; /* Green background */
}

.share-to-whatsapp:hover {
  background-color: #1c8744; /* Darker green on hover */
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .modal-content {
    margin: 10% auto;
    padding: 10px;
    max-width: 90%;
  }

  .close-button {
    font-size: 30px;
  }

  .modal-image {
    max-width: 100%;
    height: auto; /* Maintain aspect ratio */
  }
}

@media (max-width: 480px) {
  .modal-content {
    margin: 5% auto;
    padding: 10px 40px;
    max-width: 95%;
  }

  .close-button {
    font-size: 35px;
  }

  .modal-image {
    max-width: 100%;
    height: auto; /* Maintain aspect ratio */
  }
}
