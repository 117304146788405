.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -5px;
  position: relative;
  max-width: 500px;
  background-color: #fff;
}

.icon-row {
  display: flex;
  justify-content: center;
  width: 100%;
  /* padding: 50px 0 10px 0; */
}

.first-row {
  position: relative;
  top: 30px;
  margin-top: -10px;
}

.second-row {
  background-color: indigo;
  width: 100%;
  padding: 35px 0 10px 0;
}

.icon-button {
  background-color: #1060b6; /* Blue background */
  border: none;
  border-radius: 50%; /* Make the button round */
  width: 50px; /* Fixed width */
  height: 50px; /* Fixed height to ensure all buttons are the same size */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin: 5px; /* Adjust margin for spacing */
}

.icon-button .svg-inline--fa {
  color: #ffffff; /* White icon color */
  font-size: 22px; /* Consistent icon size */
}

.icon-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: scale(1.1); /* Slightly enlarge on hover */
}

.description {
  text-wrap: wrap;
  background-color: indigo;
  padding: 0px 0 40px;
  text-align: center;
}

.description p {
  color: #fff;
  padding: 20px 30px;
}

@media (max-width: 480px) {
  /* Your styles for mobile devices */
}

@media (max-width: 768px) {
  /* Your styles for small tablets and large smartphones */
}

@media (max-width: 992px) {
  /* Your styles for tablets */
}

@media (max-width: 1200px) {
  /* Your styles for landscape tablets and small laptops */
}

@media (min-width: 1201px) {
  /* Your styles for large devices */
}
