.card {
  border-radius: 0.5rem;
  color: white;
  height: 40rem;
  /* width: 32rem; */
}

@media screen and (min-width: 70rem) {
  .card {
    flex-shrink: 0;
  }
}
