@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .btn {
    @apply shadow-md py-3 px-6 rounded-md transition duration-300;
  }

  .btn-accent {
    @apply bg-indigo-600 text-white;
  }

  .btn-white {
    @apply bg-white dark:bg-neutral-200 dark:text-neutral-800;
  }
}

body {
  font-family: "Montserrat", sans-serif;
}
